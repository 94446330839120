import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Privacy Policy`}</h3>
    <h3></h3>
    <h5>{`1. Overview`}</h5>
    <p>{`Protecting your data and your privacy is a high priority and is very important to us. Pristine Bytes is pursuant to German law (“We”, “us” or “our”) adhere to a strict policy for ensuring the security and privacy of your data, in particular, your personally identifiable information (such as name, address, email address, and/or other identifiable information, collectively such personally identifiable information “Personal Data”).`}</p>
    <p>{`We provide hosted services (“Cloud Apps”) for Atlassian Cloud Products (“Atlassian Cloud Product”) via the Atlassian Marketplace (`}<a parentName="p" {...{
        "href": "https://marketplace.atlassian.com/"
      }}>{`https://marketplace.atlassian.com/`}</a>{`). The apps are delivered through the Atlassian Connect framework (“Atlassian Connect”). Cloud Apps can be identified by the “Cloud” category in the corresponding Atlassian Marketplace listing.`}</p>
    <p>{`This Privacy Policy provides you with an overview of the collection and processing of your data.`}</p>
    <p>{`In the following, all data processed by you via an Atlassian Cloud Product and one of our Apps are defined as “Customer Data”.`}</p>
    <h5>{`2. Name and Address of the Controller pursuant to Art. 4 No. 7 GDPR`}</h5>
    <p>{`Pristine Bytes`}<br />{`
Daniel Kleinert`}<br />{`
Sternbergstr. 15`}<br />{`
76131 Karlsruhe`}<br />{`
Germany`}<br /></p>
    <p>{`Phone: +49 721 90 981 444`}<br />{`
Email: support@pristinebytes.com`}<br /></p>
    <h5>{`3. General Information regarding the Data Processing`}</h5>
    <p><strong parentName="p">{`a) Scope of the data processing`}</strong></p>
    <p>{`We only collect and use personal data of our users insofar as this is necessary to provide our services. The collection and use of personal data of our users regularly takes place only with the user’s consent. An exception applies in those cases where prior consent cannot be obtained for factual reasons and the processing of the data is permitted by law.`}</p>
    <p><strong parentName="p">{`b) Legal basis for the processing of personal data`}</strong></p>
    <p>{`Insofar as we obtain the consent of the data subject for the processing of personal data, Art. 6 para.1 s.1 lit.a GDPR serves as the legal basis.`}</p>
    <p>{`If the processing of personal data is required for the performance of a contract to which the data subject is a party, Art. 6 para.1 s.1 lit.b GDPR serves as the legal basis. This also applies to processing operations that are necessary to carry out pre-contractual measures.`}</p>
    <p>{`Insofar as the processing of personal data is required to fulfil a legal obligation to which our company is subject, Art. 6 para.1 s.1 lit.c GDPR serves as the legal basis.`}</p>
    <p>{`If processing is necessary to safeguard a legitimate interest of our company or a third party and if the interests, fundamental rights and freedoms of the data subject do not outweigh the first-mentioned interest, Art. 6 para.1 s.1 lit.f GDPR serves as the legal basis for processing.`}</p>
    <p><strong parentName="p">{`c) Data deletion and storage period`}</strong></p>
    <p>{`Your personal data will be deleted or blocked as soon as the purpose of storage ceases to apply. Data may be stored beyond that time if this has been provided for by the European or national legislator in EU regulations, laws or other provisions to which we are subject.`}</p>
    <p>{`The data will also be blocked or deleted if a storage period prescribed by the aforementioned standards expires, unless there is a need for further storage of the data for the conclusion or fulfilment of a contract.`}</p>
    <h5>{`4. Your Rights as Data Subject`}</h5>
    <p>{`You have the following rights towards us regarding your personal data:`}</p>
    <ul>
      <li parentName="ul">{`Right to information,`}</li>
      <li parentName="ul">{`Right to correction or deletion,`}</li>
      <li parentName="ul">{`Right to restrict processing,`}</li>
      <li parentName="ul">{`Right to object to the processing,`}</li>
      <li parentName="ul">{`Right to data transferability.`}</li>
    </ul>
    <p>{`Please send an email to support@pristinebytes.com in order to exercise one or more of the aforementioned rights.
If you believe that the processing of personal data concerning you violates the GDPR, you have the right of appeal to a supervisory authority, without prejudice to any other remedies.`}</p>
    <p>{`If you have given your consent to the processing of your data, you can revoke this at any time. Such revocation affects the permissibility of processing your personal data after you have given it to us.`}</p>
    <p>{`If we base the processing of your personal data on the balancing of interests, you may object to the processing. This is the case if processing is not necessary, in particular, to fulfil a contract with you, which is described by us in the following description of the functions. When exercising such objection, we ask you to explain the reasons why we should not process your personal data as we have done. In the event of your justified objection, we will examine the situation and either stop or adjust data processing or point out to you our compelling reasons for protection, on the basis of which we will continue processing.`}</p>
    <p>{`Of course, you can object to the processing of your personal data for purposes of advertising and data analysis at any time. You can inform us about your advertising contradiction under the following contact data: support@pristinebytes.com.`}</p>
    <h5>{`5. General Information regarding Data Processing with our Cloud Apps`}</h5>
    <p>{`Unless otherwise stated below our Cloud Apps do not store Customer Data locally, but store Customer Data in the corresponding Atlassian Cloud Product.`}</p>
    <p><strong parentName="p">{`Exceptions:`}</strong></p>
    <p>{`Account Data: Our Cloud Apps store Customer Data provided and generated by Atlassian, that are required for license validation, contract administration and communication with you as our customer. This includes your customer ID, URL of the Atlassian Cloud Product instance, installation details and access keys.`}</p>
    <p>{`Operation Data: Our Cloud Apps temporarily store Customer Data which is required for the operation of the service. Such data for example includes macros, webhooks, issue and page content or other content accessible by the app.`}</p>
    <p>{`Customer Uploaded Data: Our Cloud Apps store Data created with and for the Cloud Apps and stored within the Cloud Apps by you using the user interface. This includes for example templates (e.g. word documents, pdf-documents), configuration data or metadata.`}</p>
    <p>{`Session Data: Our Cloud Apps store data resulting from your use of the service. This includes:`}</p>
    <ul>
      <li parentName="ul">{`IP-address`}</li>
      <li parentName="ul">{`date and time of the request`}</li>
      <li parentName="ul">{`time zone difference to Greenwich Mean Time (GMT)`}</li>
      <li parentName="ul">{`content of the request (specific page)`}</li>
      <li parentName="ul">{`access status/HTTP status code`}</li>
      <li parentName="ul">{`the amount of data transferred in each case`}</li>
      <li parentName="ul">{`website from which the request comes`}</li>
      <li parentName="ul">{`browser`}</li>
      <li parentName="ul">{`operating system and its surface`}</li>
      <li parentName="ul">{`language and version of the browser software.`}</li>
    </ul>
    <h5>{`6. Specific Information regarding particular Cloud Apps`}</h5>
    <ul>
      <li parentName="ul">{`Bulk Attachment Download: No additional data is stored.`}</li>
      <li parentName="ul">{`Mind Maps for Confluence: No additional data is stored.`}</li>
    </ul>
    <h5>{`7. Data Location`}</h5>
    <p>{`Our Cloud Apps are hosted on Amazon Web Services (AWS) and Netlify. Their respective privacy statement can be found at `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/privacy/"
      }}>{`https://aws.amazon.com/privacy/`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.netlify.com/privacy/"
      }}>{`https://www.netlify.com/privacy/`}</a>{`.`}</p>
    <p>{`Error and access reports are stored with Sentry. The Sentry privacy statement can be found at `}<a parentName="p" {...{
        "href": "https://sentry.io/privacy/"
      }}>{`https://sentry.io/privacy/`}</a>{`.`}</p>
    <h5>{`8. Access to Customer Data`}</h5>
    <p>{`To some extent, we use external service providers to process your data (e.g. AWS). These have been carefully selected and commissioned by us, are bound by our instructions and are checked regularly. Our external service providers process your personal data on the basis of a data protection agreement that complies with legal requirements.`}</p>
    <p>{`These service providers belong to the following categories:`}</p>
    <ul>
      <li parentName="ul">{`hosting service providers,`}</li>
      <li parentName="ul">{`billing service providers,`}</li>
      <li parentName="ul">{`payment settlement service providers,`}</li>
      <li parentName="ul">{`user authentication service providers,`}</li>
      <li parentName="ul">{`communication service provider,`}</li>
      <li parentName="ul">{`fault and malfunction analysis service providers,`}</li>
      <li parentName="ul">{`analytics service providers,`}</li>
      <li parentName="ul">{`customer relationship management service providers.`}</li>
    </ul>
    <p>{`Some of the external service providers are located in so-called third countries outside the European Union. In order to guarantee an adequate level of data protection, the service providers have either (i) submitted to the EU-US Privacy Shield (see `}<a parentName="p" {...{
        "href": "https://www.privacyshield.gov/EU-US-Framework"
      }}>{`https://www.privacyshield.gov/EU-US-Framework`}</a>{`), or (ii) we have concluded data protection agreements with the providers on the basis of the so-called EU standard contract clauses, `}<a parentName="p" {...{
        "href": "https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32010D0087&from=EN"
      }}>{`https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32010D0087&from=EN`}</a>{`.`}</p>
    <p>{`The inclusion of the service providers is based on Art. 6 para.1 s.1 lit.f GDPR. It is necessary in order to be able to provide the services.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      