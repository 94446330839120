/** @jsx jsx */
import {jsx} from 'theme-ui'
import PrivacyPolicyMDX from "../sections/privacy-policy.mdx"
import Layout from "../components/layout"

const PrivacyPolicy = () => (
    <Layout>
      <PrivacyPolicyMDX/>
    </Layout>
)

export default PrivacyPolicy
